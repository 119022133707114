// live
export const domain_id = "4";
export const IMG_BASE_URL =
  "https://cloudxperte.crm-api.wifiattendance.com:3016/";

export const BASE_URL =
  "https://cloudxperte.crm-api.wifiattendance.com:3016/api/";

// export const BASE_URL = "http://192.168.1.35:3016/api/";

export const ApiConfiq = {
  GET_MODULES: BASE_URL + "dis-module-list?domain_id=", //domain_id
  GET_SUB_MODULES: BASE_URL + "dis-submodule-list?module_name=", // module name
  GET_QUES_LIST: BASE_URL + "que-sublist?sub_module_by_name=", //sub module
  GET_ANSWERS: BASE_URL + "webhooks/rest/webhook",
  ADD_NO_RES_QUES: BASE_URL + "add-nores-question",
  LOGIN: "https://api.vehicletracking.qa:3000/login",
  SIGNUP: "https://webapps.vehicletracking.qa/Register",
  UPDATE_CONVERSATION: BASE_URL + "update-sender-id",
  CHATBOT_MESSAGES: BASE_URL + 'chatbot-messages'
};
