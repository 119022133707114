import React, { useEffect, useRef } from "react";
import { IMG_BASE_URL } from "./api/apiConfig";
import { useState } from "react";
const Module = ({
  message,
  dat,
  indx,
  selectedModule,
  colorSettings,
  getNext,
}) => {
  const scrollContainerRef = useRef(null);

  const [selected, setSelected] = useState('not-selected')
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      scrollContainer.classList.add("active");
      startX = e.pageX - scrollContainer.offsetLeft;
      scrollLeft = scrollContainer.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
      scrollContainer.classList.remove("active");
    };

    const handleMouseUp = () => {
      isDown = false;
      scrollContainer.classList.remove("active");
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - scrollContainer.offsetLeft;
      const walk = (x - startX) * 2;
      scrollContainer.scrollLeft = scrollLeft - walk;
    };

    scrollContainer.addEventListener("mousedown", handleMouseDown);
    scrollContainer.addEventListener("mouseleave", handleMouseLeave);
    scrollContainer.addEventListener("mouseup", handleMouseUp);
    scrollContainer.addEventListener("mousemove", handleMouseMove);

    return () => {
      scrollContainer.removeEventListener("mousedown", handleMouseDown);
      scrollContainer.removeEventListener("mouseleave", handleMouseLeave);
      scrollContainer.removeEventListener("mouseup", handleMouseUp);
      scrollContainer.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="scroll-container" ref={scrollContainerRef}>
      <div className="plansCardBox ">
        {message?.data?.map((dat, indx) => {
          return (
            <div
              key={indx}
              className={"text-cent plansCard mt-2"}
              style={
                selected == (dat.module_by_name || dat.sub_module_by_name)
                  ? {
                    backgroundColor:
                      colorSettings?.data?.primary_background_color,
                    color: "white",
                  }
                  : null
              }
              onClick={() => {
                setSelected(
                  message.type == "MODULE"
                    ? dat.module_by_name
                    : dat.sub_module_by_name)
                getNext(
                  message.type,
                  message.type == "MODULE"
                    ? dat.module_by_name
                    : dat.sub_module_by_name
                );
                // setActiveName(i.module);
                // setActive(i.module);
                // test(i.module);
                //  alert(i.module)
              }}
            >
              {/* style={active === i.module ? { backgroundColor: colorSettings?.data?.primary_background_color, color: "white" } : null} */}

              <img
                src={`${message?.type == "MODULE"
                  ? IMG_BASE_URL + dat?.module_by_icon
                  : IMG_BASE_URL + dat.sub_module_icon
                  }`}
                onError={(e) => e.target.style.display = "none"}
              //alt={dat.module_by_display_name}
              />
              <h6
                className="serviceTitle small_font_size"
              // style={active === i.module ? { color: "white" } : null}
              >
                {message?.type == "MODULE"
                  ? dat.module_by_display_name
                    ? dat.module_by_display_name
                    : dat.module_by_name
                  : dat?.sub_module_by_display_name
                    ? dat?.sub_module_by_display_name
                    : dat?.sub_module_by_name}
              </h6>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Module;
